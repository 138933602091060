import React, { useContext, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import './BookingScreen.css';

import Text from '@exploreshare/everest/base/Text';
import EverestLink from '@exploreshare/everest/base/Link';
import Grid from '@exploreshare/everest/base/Grid';
import Image from '@exploreshare/everest/base/Image';
import Button from '@exploreshare/everest/base/Button';

import PricingSummary from '../PricingSummary';
import BookingContext from '../../contexts/BookingContext';
import InvoicesTable from '../../components/InvoicesTable/InvoicesTable';
import { repathImg, REPOSITORIES } from '../../util/repathImg';

const BookingScreen = () => {
	const { selectBooking, selectedBooking: booking, loadingBookingDetails } = useContext(
		BookingContext
	);
	const { bookingId } = useParams();
	const history = useHistory();

	useEffect(() => {
		selectBooking(bookingId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bookingId]);

	if (loadingBookingDetails) return <div>Loading...</div>;

	if (!booking) return <div>The booking doesn't exist</div>;

	const { trip, guide, lead } = booking;

	return (
		<>
			<Link
				onClick={e => {
					e.preventDefault();
					history.goBack();
				}}
				component={EverestLink}>
				&larr; Go back
			</Link>
			<div className="title-container">
				<Image
					className="title-image"
					src={`${repathImg(trip.cover_url, REPOSITORIES.TRIP_COVER, {
						_id: trip._id,
					})}?h=200`}
					alt="trip_cover_image"
				/>
				<Text size={25} lineHeight={40} inline bold>
					{booking.code}&nbsp;
				</Text>
				<Text size={25} lineHeight={40} inline>
					{trip.title}
				</Text>
			</div>

			<Grid container>
				<Grid item sm={6} noPaddingX>
					<Text inline bold>
						Guide:&nbsp;
					</Text>
					<Link to={`/guides/${guide._id}`} component={EverestLink}>
						{guide.first_name} {guide.last_name} ({guide.email})
					</Link>
					<br />

					<Text inline bold>
						Customer:&nbsp;
					</Text>
					<Link to={`/customers/${lead._id}`} component={EverestLink}>
						{lead.first_name || lead.last_name
							? `${lead.first_name} ${lead.last_name} (${lead.email})`
							: lead.email}
					</Link>
					<br />

					<Text inline bold>
						Departure date:&nbsp;
					</Text>
					<Text inline>{format(parseISO(booking.departure_date), 'dd-MM-yyyy')}</Text>
				</Grid>
				<Grid item sm={6} noPaddingX>
					<h4>Pricing summary</h4>
					<PricingSummary booking={booking} />
				</Grid>
			</Grid>
			<Grid container style={{ marginTop: 20 }} spacing={16}>
				<Grid item sm={12} noPaddingX>
					<h4>Booking invoices</h4>
					<InvoicesTable invoices={booking.invoices} />
					<h5 style={{ marginTop: 30 }}>Issue invoices for booking {booking.code}</h5>
					<Button
						style={{ marginTop: 10, display: 'inline-block' }}
						onClick={() =>
							history.push(`/invoices/new/invoice?type=trip-commission&booking=${booking.code}`)
						}>
						Trip commission invoice
					</Button>
					<Button
						style={{ marginTop: 10, display: 'inline-block', marginLeft: '5px' }}
						onClick={() =>
							history.push(`/invoices/new/invoice?type=customer-fee&booking=${booking.code}`)
						}>
						Customer fee invoice
					</Button>
					<Button
						style={{ marginTop: 10, display: 'inline-block', marginLeft: '5px' }}
						onClick={() =>
							history.push(`/invoices/new/invoice?type=admin-fee&booking=${booking.code}`)
						}>
						Admin fee invoice
					</Button>
				</Grid>
				{/*<Grid item sm={6} noPaddingX>
					<h4>Pricing summary</h4>
					<PricingSummary booking={booking} />
					</Grid>*/}
			</Grid>
		</>
	);
};

export default BookingScreen;
