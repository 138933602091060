import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Image from '@exploreshare/everest/base/Image';
import Input from '@exploreshare/everest/base/Input';
import Grid from '@exploreshare/everest/base/Grid';
import Select from '@exploreshare/everest/base/Select';
import Button from '@exploreshare/everest/base/Button';
import Text from '@exploreshare/everest/base/Text';
import CheckBox from '@exploreshare/everest/base/CheckBox';
import EverestLink from '@exploreshare/everest/base/Link';
import PhoneInput from '@exploreshare/everest/base/PhoneInput';

import InvoicesTable from '../components/InvoicesTable';
import BookingsTable from '../components/BookingsTable';

import GuideContext from '../contexts/GuideContext';
import BookingContext from '../contexts/BookingContext';
import { useCountries } from '../contexts/CountriesContext';
import invoicingCategories from '../util/invoicingCategories';
import currencies from '../util/currencies';
import { useNotifications } from '../contexts/NotificationContext';
import { repathImg, REPOSITORIES } from '../util/repathImg';

const buildQueryParams = obj =>
	Object.keys(obj)
		.map(key => `${key}=${encodeURIComponent(obj[key] || '')}`)
		.join('&');

const GuideScreen = () => {
	const { guideId } = useParams();
	const {
		selectedGuide: guide,
		loadingGuideDetails,
		selectGuide,
		loading,
		handleChange,
		saveChanges,
	} = useContext(GuideContext);
	const { fetchBookingsAtPage, bookingList } = useContext(BookingContext);
	const countries = useCountries();
	const history = useHistory();
	const notify = useNotifications();
	const [bookingsPage, setBookingsPage] = useState(0);
	const [phone, setPhone] = useState({});

	const onBookingsPrevPage = () => {
		fetchBookingsAtPage({
			query: '',
			page: bookingsPage - 1,
			filters: `guide._id=${guideId}`,
		});
		setBookingsPage(bookingsPage - 1);
	};

	const onBookingsNextPage = () => {
		fetchBookingsAtPage({
			query: '',
			page: bookingsPage + 1,
			filters: `guide._id=${guideId}`,
		});
		setBookingsPage(bookingsPage + 1);
	};

	useEffect(() => {
		const fetchBookings = async () => {
			fetchBookingsAtPage({
				query: '',
				page: bookingsPage,
				filters: `guide._id=${guideId}`,
			});
		};
		selectGuide(guideId);
		fetchBookings();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [guideId]);

	if (loadingGuideDetails || loading) return <h1>Loading</h1>;

	const { dirty } = guide.billing;

	const copyTypeformLink = async () => {
		await navigator.clipboard.writeText(
			`https://exploreshare.typeform.com/to/phHl9q?${buildQueryParams({
				guide_id: guideId,
				la_first_name: guide.first_name,
				la_last_name: guide.last_name,
				billing_first_name: guide.billing.first_name,
				billing_last_name: guide.billing.last_name,
				billing_legal_name: guide.billing.legal_name,
				billing_legal_status: guide.billing.is_company ? 'Company' : 'Individual',
				billing_email: guide.billing.email,
				billing_phone: guide.billing.phone,
				billing_address: guide.billing.address,
				billing_address_number: guide.billing.address_number,
				billing_country: guide.billing.country,
				billing_city: guide.billing.city,
				billing_zip_code: guide.billing.zip_code,
			})}`
		);
		notify.success('Link has been copied to the clipboard');
	};

	const renderPaymentDetails = () => {
		if (!guide.billing.payment_currency) return null;

		if (['EUR', 'PLN', 'SEK', 'NOK', 'CHF', 'ISK'].includes(guide.billing.payment_currency)) {
			return (
				<Grid container spacing={4}>
					<Grid item noPaddingX>
						<Input
							label="IBAN Number"
							placeholder="IBAN Number"
							value={guide.billing.account_iban_number || ''}
							isValid={guide.billing.account_iban_number}
							handleChange={handleChange('account_iban_number')}
							errorLabels={['Missing IBAN number']}
						/>
					</Grid>
				</Grid>
			);
		} else if (guide.billing.payment_currency === 'USD') {
			return (
				<Grid container spacing={4}>
					<Grid item noPaddingX>
						<Input
							label="Account number"
							placeholder="Account number"
							value={guide.billing.account_iban_number || ''}
							isValid={guide.billing.account_iban_number}
							handleChange={handleChange('account_iban_number')}
							errorLabels={['Missing Account number']}
						/>
					</Grid>
					<Grid item>
						<Input
							label="Routing number"
							placeholder="Routing number"
							value={guide.billing.routing_swift_number || ''}
							isValid={guide.billing.routing_swift_number}
							handleChange={handleChange('routing_swift_number')}
							errorLabels={['Missing routing number']}
						/>
					</Grid>
					<Grid item>
						<Input
							label="Checking or Savings account"
							placeholder="Checking or Savings account"
							value={guide.billing.account_category || ''}
							isValid={guide.billing.account_category}
							handleChange={handleChange('routing_swift_number')}
							errorLabels={['Missing account type']}
						/>
					</Grid>
				</Grid>
			);
		} else if (guide.billing.payment_currency === 'CAD') {
			return (
				<Grid container spacing={4}>
					<Grid item>
						<Input
							label="Institution Number"
							placeholder="Institution Number"
							value={guide.billing.institution_number || ''}
							isValid={guide.billing.institution_number}
							handleChange={handleChange('institution_number')}
							errorLabels={['Missing institution number']}
						/>
					</Grid>
					<Grid item noPaddingX>
						<Input
							label="Transit and Account number"
							placeholder="Transit and Account number"
							value={guide.billing.account_iban_number || ''}
							isValid={guide.billing.account_iban_number}
							handleChange={handleChange('account_iban_number')}
							errorLabels={['Missing 	Transit and Account number']}
						/>
					</Grid>
					<Grid item>
						<Input
							label="Checking or Savings account"
							placeholder="Checking or Savings account"
							value={guide.billing.account_category || ''}
							isValid={guide.billing.account_category}
							handleChange={handleChange('routing_swift_number')}
							errorLabels={['Missing account type']}
						/>
					</Grid>
				</Grid>
			);
		} else if (guide.billing.payment_currency === 'ARS') {
			return (
				<Grid container spacing={4}>
					<Grid item noPaddingX>
						<Input
							label="CBU"
							placeholder="CBU"
							value={guide.billing.account_iban_number || ''}
							isValid={guide.billing.account_iban_number}
							handleChange={handleChange('account_iban_number')}
							errorLabels={['Missing CBU']}
						/>
					</Grid>
					<Grid item>
						<Input
							label="CUIT/CUIL"
							placeholder="CUIT/CUIL"
							value={guide.billing.tax_id || ''}
							isValid={guide.billing.tax_id}
							handleChange={handleChange('tax_id')}
							errorLabels={['Missing CUIT/CUIL']}
						/>
					</Grid>
				</Grid>
			);
		} else if (guide.billing.payment_currency === 'NZD') {
			return (
				<Grid container spacing={4}>
					<Grid item noPaddingX>
						<Input
							label="Account number"
							placeholder="Account number"
							value={guide.billing.account_iban_number || ''}
							isValid={guide.billing.account_iban_number}
							handleChange={handleChange('account_iban_number')}
							errorLabels={['Missing Account number']}
						/>
					</Grid>
				</Grid>
			);
		} else if (guide.billing.payment_currency === 'GBP') {
			return (
				<Grid container spacing={4}>
					<Grid item>
						<Input
							label="UK Sort code"
							placeholder="UK Sort code"
							value={guide.billing.routing_swift_number || ''}
							isValid={guide.billing.routing_swift_number}
							handleChange={handleChange('routing_swift_number')}
							errorLabels={['Missing UK sort code']}
						/>
					</Grid>
					<Grid item noPaddingX>
						<Input
							label="Account number"
							placeholder="Account number"
							value={guide.billing.account_iban_number || ''}
							isValid={guide.billing.account_iban_number}
							handleChange={handleChange('account_iban_number')}
							errorLabels={['Missing Account number']}
						/>
					</Grid>
				</Grid>
			);
		} else if (guide.billing.payment_currency === 'JPY') {
			return (
				<Grid container spacing={4}>
					<Grid item>
						<Input
							label="Bank name"
							placeholder="Bank name"
							value={guide.billing.bank_name || ''}
							isValid={guide.billing.bank_name}
							handleChange={handleChange('bank_name')}
							errorLabels={['Missing bank name']}
						/>
					</Grid>
					<Grid item>
						<Input
							label="Branch name"
							placeholder="Branch name"
							value={guide.billing.branch_name || ''}
							isValid={guide.billing.branch_name}
							handleChange={handleChange('branch_name')}
							errorLabels={['Missing branch name']}
						/>
					</Grid>
					<Grid item>
						<Input
							label="Name in katakana"
							placeholder="Name in katakana"
							value={guide.billing.name_in_katakana || ''}
							isValid={guide.billing.name_in_katakana}
							handleChange={handleChange('name_in_katakana')}
							errorLabels={['Missing name in katakana']}
						/>
					</Grid>
					<Grid item noPaddingX>
						<Input
							label="Account number"
							placeholder="Account number"
							value={guide.billing.account_iban_number || ''}
							isValid={guide.billing.account_iban_number}
							handleChange={handleChange('account_iban_number')}
							errorLabels={['Missing Account number']}
						/>
					</Grid>
					<Grid item>
						<Input
							label="Account type (Ordinary or Savings)"
							placeholder="Account type (Ordinary or Savings)"
							value={guide.billing.account_category || ''}
							isValid={guide.billing.account_category}
							handleChange={handleChange('routing_swift_number')}
							errorLabels={['Missing account type']}
						/>
					</Grid>
				</Grid>
			);
		}
	};

	return (
		<>
			<table className="guide-screen__title-table">
				<tbody>
					<tr>
						<td style={{ width: '200px' }}>
							<Image
								alt={`${guide.first_name} ${guide.last_name}`}
								className="avatar-image avatar-image-big"
								rounded
								src={
									`${repathImg(guide.avatar_url, REPOSITORIES.GUIDE, {
										_id: guide._id,
									})}?h=180&w=180` || 'https://unsplash.it/80/80'
								}
							/>
						</td>
						<td>
							<h1>
								{guide.first_name} {guide.last_name}
							</h1>
							<span className="muted">{guide.email}</span>
						</td>
						<td style={{ verticalAlign: 'top', textAlign: 'right' }}>
							<Link
								onClick={e => {
									e.preventDefault();
									history.goBack();
								}}
								component={EverestLink}>
								&larr; Go back
							</Link>
						</td>
					</tr>
				</tbody>
			</table>
			<Tabs>
				<TabList>
					<Tab>Contact details</Tab>
					<Tab>Billing address</Tab>
					<Tab>Invoicing options</Tab>
					<Tab>Billing account</Tab>
					<Tab>Additional information</Tab>
					<Tab>Invoices</Tab>
					<Tab>Bookings</Tab>
				</TabList>

				<TabPanel>
					<h3>Contact Details</h3>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs={6}>
							<Input
								label="First name"
								placeholder="First name"
								isValid={guide.billing.first_name}
								value={guide.billing.first_name}
								handleChange={handleChange('first_name')}
							/>
						</Grid>
						<Grid item xs={6}>
							<Input
								label="Last name"
								placeholder="Last name"
								isValid={guide.billing.last_name}
								value={guide.billing.last_name}
								handleChange={handleChange('last_name')}
							/>
						</Grid>
						<Grid item xs={6}>
							<Input
								label="Legal name"
								placeholder="Legal name"
								isValid={guide.billing.legal_name}
								value={guide.billing.legal_name}
								handleChange={handleChange('legal_name')}
							/>
						</Grid>
						<Grid item xs={6}>
							<Grid container>
								<Grid item>
									<CheckBox
										selected={Boolean(guide.billing.is_company)}
										onClick={checked => handleChange('is_company')({ target: { value: checked } })}
									/>
								</Grid>
								<Grid item>
									<Text inline>Is a company?</Text>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Input
								label="Email"
								placeholder="Email"
								isValid={guide.billing.email}
								value={guide.billing.email}
								handleChange={handleChange('email')}
							/>
						</Grid>
						<Grid item xs={12}>
							<PhoneInput
								label="Phone"
								value={guide.billing.phone}
								isValid={phone.isValid}
								errorLabels={['Invalid phone number']}
								handleChange={phone => {
									setPhone(phone);
									handleChange('phone')({ target: { value: phone.number } });
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button onClick={copyTypeformLink}>Copy Typeform Link</Button>
						</Grid>
					</Grid>
					{(dirty.legal_name ||
						dirty.first_name ||
						dirty.last_name ||
						dirty.is_company ||
						dirty.email ||
						dirty.phone) && (
						<Button
							primary
							onClick={saveChanges([
								'legal_name',
								'first_name',
								'last_name',
								'is_company',
								'email',
								'phone',
							])}>
							Save
						</Button>
					)}
				</TabPanel>

				<TabPanel>
					<h3>Billing Address</h3>
					<Grid container spacing={1}>
						<Grid item xs={8} className="no-pad-left">
							<Input
								label="Address"
								placeholder="Address"
								value={guide.billing.address}
								isValid={guide.billing.address}
								errorLabels={['Missing address']}
								handleChange={handleChange('address')}
							/>
						</Grid>
						<Grid item xs={4}>
							<Input
								label="Address number"
								placeholder="Address number"
								value={guide.billing.address_number}
								isValid={guide.billing.address_number}
								handleChange={handleChange('address_number')}
								errorLabels={['Missing address number']}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={1}>
						<Grid item xs={4} className="no-pad-left">
							<Select
								fullWidth
								options={countries.map(({ title, _id }) => ({ value: title, label: title }))}
								label="Country"
								placeholder="Select a value..."
								handleChange={handleChange('country')}
								errorLabels={['Please select an option']}
								value={guide.billing.country}
								isValid={guide.billing.country}
							/>
						</Grid>
						<Grid item xs={4}>
							<Input
								label="City"
								placeholder="City"
								value={guide.billing.city}
								isValid={guide.billing.city}
								handleChange={handleChange('city')}
								errorLabels={['Missing city']}
							/>
						</Grid>
						<Grid item xs={4} className="no-pad-left">
							<Input
								label="ZIP code"
								placeholder="ZIP code"
								value={guide.billing.zip_code}
								isValid={guide.billing.zip_code}
								handleChange={handleChange('zip_code')}
								errorLabels={['Missing ZIP code']}
							/>
						</Grid>
					</Grid>
					{(dirty.address ||
						dirty.address_number ||
						dirty.country ||
						dirty.city ||
						dirty.zip_code) && (
						<Button
							primary
							onClick={saveChanges(['address', 'address_number', 'country', 'city', 'zip_code'])}>
							Save
						</Button>
					)}
				</TabPanel>

				<TabPanel>
					<h3>Invoicing Options</h3>
					<Grid container spacing={4}>
						<Grid item className="no-pad-left">
							<Select
								fullWidth
								options={invoicingCategories}
								label="Invoice Category"
								placeholder="Select a value..."
								handleChange={handleChange('invoicing_category')}
								errorLabels={['Please select an option']}
								value={guide.billing.invoicing_category}
								isValid={guide.billing.invoicing_category}
							/>
						</Grid>
						<Grid item>
							<Input
								label="VAT Number"
								placeholder="VAT Number"
								value={guide.billing.vat_number}
								isValid={guide.billing.vat_number}
								handleChange={handleChange('vat_number')}
								errorLabels={['Missing VAT number']}
							/>
						</Grid>
					</Grid>
					{(dirty.invoicing_category || dirty.vat_number) && (
						<Button primary onClick={saveChanges(['invoicing_category', 'vat_number'])}>
							Save
						</Button>
					)}
				</TabPanel>

				<TabPanel>
					<h3>Billing Account</h3>
					<Grid container spacing={4}>
						<Grid item xs={4} className="no-pad-left">
							<Select
								fullWidth
								options={countries.map(({ title, _id }) => ({ value: title, label: title }))}
								label="Bank Country"
								placeholder="Select a value..."
								handleChange={handleChange('bank_country')}
								errorLabels={['Please select an option']}
								value={guide.billing.bank_country}
								isValid={guide.billing.bank_country}
							/>
						</Grid>
						<Grid item xs={2} className="no-pad-left">
							<Select
								fullWidth
								options={currencies.map(c => ({ value: c, label: c }))}
								label="Currency"
								placeholder="Select a value..."
								handleChange={handleChange('payment_currency')}
								errorLabels={['Please select an option']}
								value={guide.billing.payment_currency}
								isValid={guide.billing.payment_currency}
							/>
						</Grid>
					</Grid>
					{renderPaymentDetails()}
					{(dirty.account_holder ||
						dirty.account_iban_number ||
						dirty.account_type ||
						dirty.institution_number ||
						dirty.routing_swift_number ||
						dirty.bank_name ||
						dirty.branch_name ||
						dirty.name_in_katakana ||
						dirty.account_category ||
						dirty.payment_currency ||
						dirty.bank_country ||
						dirty.tax_id) && (
						<Button
							primary
							onClick={saveChanges([
								'account_holder',
								'account_iban_number',
								'account_type',
								'institution_number',
								'routing_swift_number',
								'bank_name',
								'branch_name',
								'name_in_katakana',
								'account_category',
								'payment_currency',
								'bank_country',
								'tax_id',
							])}>
							Save
						</Button>
					)}
				</TabPanel>

				<TabPanel>
					<h3>Additional Information</h3>
					<Input
						multiline
						value={guide.billing.comments}
						isValid={guide.billing.comments}
						label="Comments"
						placeholder="Comments"
						handleChange={handleChange('comments')}
						rows={6}
					/>
					<Input
						multiline
						value={guide.billing.billing_comments}
						isValid={guide.billing.billing_comments}
						label="Billing Comments"
						placeholder="Billing Comments"
						handleChange={handleChange('billing_comments')}
						rows={6}
					/>
					{(dirty.comments || dirty.billing_comments) && (
						<Button primary onClick={saveChanges(['comments', 'billing_comments'])}>
							Save
						</Button>
					)}
				</TabPanel>

				<TabPanel>
					<h3>Invoices</h3>
					<InvoicesTable invoices={guide.billing.invoices} />
					<Button
						primary
						style={{ marginTop: 10 }}
						onClick={() => {
							history.push('/invoices/new/invoice?type=trip-commission');
						}}>
						Issue invoice to {guide.first_name} {guide.last_name}
					</Button>
				</TabPanel>

				<TabPanel>
					<h3>Bookings</h3>
					<BookingsTable
						bookings={bookingList}
						onPrevPage={onBookingsPrevPage}
						onNextPage={onBookingsNextPage}
					/>
				</TabPanel>
			</Tabs>
		</>
	);
};

export default GuideScreen;
